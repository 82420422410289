import React from 'react';
import { Media } from 'reactstrap';
import { TextRow, RoundShape } from 'react-placeholder/lib/placeholders';

const Charger = () => (
  <tr>
    <th scope="row">
      <Media className="align-items-center">
        <TextRow className='show-loading-animation' color='#CDCDCD' styled={{ width: 30, height: 30 }}/>
      </Media>
    </th>
    <td>
      <TextRow className='show-loading-animation' color='#CDCDCD' />
    </td>
    <td>
      <TextRow className='show-loading-animation' color='#CDCDCD' />
    </td>
    <td className='text-center'>
      <TextRow className='show-loading-animation' color='#CDCDCD' />
    </td>
    <td className="text-right">
    <RoundShape className='show-loading-animation' color='#CDCDCD' style={{width: 10, height: 10}} />
    </td>
  </tr>
);

export default Charger;