import React from 'react';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';
import { TextRow, RoundShape } from 'react-placeholder/lib/placeholders';

const HeaderCard = () => (
  <Col lg="6" xl="3">
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              <TextRow className='show-loading-animation' color='#CDCDCD' />
            </CardTitle>
            <span className="h1 font-weight-bold mb-0">
              <TextRow className='show-loading-animation' color='#CDCDCD' />
            </span>
          </div>
          <Col className="col-auto">
            <RoundShape className='show-loading-animation' color='#CDCDCD' style={{width: 50, height: 50}} />
          </Col>
        </Row>
        <TextRow className='show-loading-animation' color='#CDCDCD' />
      </CardBody>
    </Card>
  </Col>
);

export default HeaderCard;