import { compact, find, head, isEmpty, map } from 'lodash';

export const normalizeLightningEvses = ({
  connectors,
  id,
  available,
}) => {
  const hasCCS = !isEmpty(find(connectors, { type: 'CCS' }));
  
  if (!hasCCS) {
    return null;
  }

  return {
    connector: head(connectors),
    id,
    available,
  }
};

export const normalizeLightningCharger = ({
  id,
  evses,
  name,
  operatorName,
  city,
}) => ({
  id,
  evses: compact(map(evses, normalizeLightningEvses)),
  name,
  operatorName,
  city,
});
