import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from 'react-ga';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "react-placeholder/lib/reactPlaceholder.css";

import Dashboard from 'views/Dashboard';
import Lightning from 'views/Lightning';
// import Login from 'views/examples/Login';

// import AuthLayout from "layouts/Auth.js";

ReactGA.initialize('UA-222291567-1');
Sentry.init({
  dsn: "https://df324d8c68c7448b8e53737fc3c520c8@o1153535.ingest.sentry.io/6232666",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/auth" render={() => <Login />} /> */}
      <Route path="/lightning-chargers" render={() => <Lightning />} />
      <Route path="/" render={() => <Dashboard />} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
