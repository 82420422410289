import React from 'react';
import { Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';

const HeaderCard = () => (
  <Col lg="6" xl="3">
    <Card className="card-stats mb-4 mb-xl-0">
      <CardBody>
        <Row>
          <div className="col">
            <CardTitle
              tag="h5"
              className="text-uppercase text-muted mb-0"
            >
              Nearest lightning charger
            </CardTitle>
            <span className="h1 font-weight-bold mb-0">
              Coming soon
            </span>
          </div>
          <Col className="col-auto">
            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
              <i className="ni ni-delivery-fast" />
            </div>
          </Col>
        </Row>
        <p className="mt-3 mb-0 text-muted text-sm">
          <span className="text-nowrap">
            More data to come
          </span>
        </p>
      </CardBody>
    </Card>
  </Col>
);

const LightningHeader = ({ getAllChargers }) => {
  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <HeaderCard />
              <Col lg="6" xl="3">
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Re-fetch data
                        </CardTitle>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                          <i className="fas fa-percent" />
                        </div>
                      </Col>
                    </Row>
                    <Button
                      color="info"
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();

                        getAllChargers();
                      }}
                    >
                      Re-fetch chargers data
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default LightningHeader;
