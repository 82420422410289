import { filter, isNil, find, toInteger } from 'lodash';
import React from 'react';
import { Button, Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import HeaderPlaceholder from 'components/Placeholders/Card';

const Header = ({ getAllChargers, reducer }) => {
  const [{ chargers, loading, time }] = reducer;

  const renderCard = (chargerId, optinalChargerId = null) => {
    let charger = find(chargers, { id: toInteger(chargerId) });

    if (isNil(charger)) {
      return null;
    }

    if (!isNil(optinalChargerId)) {
      const secondCharger = find(chargers, { id: toInteger(optinalChargerId) });

      if (!isNil(secondCharger)) {
        charger = {
          ...charger,
          evses: [...charger.evses, ...secondCharger.evses],
        };
      }
    }

    const { name, evses } = charger;
    const availableCharger = filter(evses, ev => ev.available && ev.currency !== 'EUR');
    const filteredChargers = filter(evses, ['currency', 'DKK']);

    return (
      <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  {name}
                </CardTitle>
                <span className="h1 font-weight-bold mb-0">
                  {`${availableCharger.length} / ${filteredChargers.length}`}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                  <i className="fas fa-chart-bar" />
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-muted text-sm">
              <span className="text-nowrap">Date fetched at: {isNil(time) ? 'No time' : time.format('YYYY-MM-DD HH:mm')}</span>
            </p>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              {loading && <HeaderPlaceholder />}
              {!loading && (
                <>
                  {/* Edvard Thomsens */}
                  {renderCard('13585', '131697')}
                  {/* Kay Fisker */}
                  {renderCard('13617')}
                  {/* Orestad Boulevard */}
                  {renderCard('131706', '13605')}
                  <Col lg="6" xl="3">
                    <Card className="card-stats mb-4 mb-xl-0">
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Re-fetch data
                            </CardTitle>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-info text-white rounded-circle shadow">
                              <i className="fas fa-percent" />
                            </div>
                          </Col>
                        </Row>
                        <Button
                          color="info"
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();

                            getAllChargers();
                          }}
                        >
                          Re-fetch chargers data
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
