import React from 'react';
import { Button, Table } from 'reactstrap';

const TableData = ({ id, address, city, supportPhoneNumber, operatorName, name }) => {
  return (
    <Table className="align-items-center" responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col"></th>
          <th scope="col">Data</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            Charger ID
          </th>
          <td>{id}</td>
          <td>
            -
          </td>
        </tr>
        <tr>
          <th scope="row">
            Charger name
          </th>
          <td>{name}</td>
          <td>
            -
          </td>
        </tr>
        <tr>
          <th scope="row">
            Address
          </th>
          <td>{`${address}, ${city}`}</td>
          <td>
            <a href={`http://maps.google.com/maps?q=${address}, ${city}`}>
              <Button
                className="btn-icon btn-2"
                color="primary"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-atom" />
                </span>
              </Button>
            </a>
          </td>
        </tr>
        <tr>
          <th scope="row">
            Support Phone Number
          </th>
          <td>{supportPhoneNumber}</td>
          <td>
            <a href={`tel:${supportPhoneNumber}`}>
              <Button
                className="btn-icon btn-2"
                color="primary"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="ni ni-atom" />
                </span>
              </Button>
            </a>
          </td>
        </tr>
        <tr>
          <th scope="row">
            Operator Name
          </th>
          <td>{operatorName}</td>
          <td>
            -
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default TableData;
