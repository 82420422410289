import Axios from 'axios';
import { filter, get, map, truncate } from 'lodash';
import { arrayOf, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
} from 'reactstrap';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import CCSIcon from 'assets/img/icons/ccs.png'
import ChargerPlaceholder from 'components/Placeholders/Charger';
import { normalizeLightningCharger } from 'variables/Normalizers';
import Header from 'views/Lightning/Header';
import MainLayout from 'layouts/Main';

const Icon = styled.a`
  background-color: #fff;
`;

const Item = ({ evses, city, name }) => {
  const availableCharger = filter(evses, ['available', true]);

  const getStatus = () => {
    const isAvailable = availableCharger.length > 0;

    return (
      <Badge color="" className="badge-dot mr-4">
        <i className={`bg-${isAvailable ? 'success' : 'danger'}`} />
        {isAvailable ? 'Available' : 'Busy'}
      </Badge>
    );
  };

  return (
    <tr>
      <th scope="row">
        <Media className="align-items-center">
          <Icon
            className="avatar rounded-circle mr-3"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <img
              alt="..."
              src={CCSIcon}
            />
          </Icon>
          <Media>
            <span className="mb-0 text-sm">
              {truncate(name)}
            </span>
          </Media>
        </Media>
      </th>
      <td>{city}</td>
      <td>{`${get(evses, '0.connector.maxKw', '-')} KW`}</td>
      <td>
        {getStatus()}
      </td>
      <td className="text-center">
        <span className="mb-0 text-sm">
          {`${availableCharger.length} / ${evses.length}`}
        </span>
      </td>
    </tr>
  );
};

Item.propTypes = {
  evses: arrayOf(shape()).isRequired,
  name: string.isRequired,
  city: string.isRequired,
};

const ligthningChargersIds = [
  '41263', // Faro
  '29282', // Karslunde way to Copenhagen
  '29266', // Karslunde way to Koge
  // '31652', // Korsor (3 chargers)
  '31654', // Korsor (1 charger)
  '29274', // Nyborg
  '29268', // Fredericia
  '29279', // Norager
  '134353', // Amager
];

const Lightning = () => {
  ReactGA.pageview('/lightning-chargers');
  const [chargers, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getChargersData = () => {
    const fetchData = async (chargerId) => {
      try {
        const {
          data,
        } = await Axios.get(`https://api.virta-ev.com/v4/stations/${chargerId}`);

        setData(prevState => [
          ...prevState,
          normalizeLightningCharger(data),
        ])
      } catch (err) {
        // nothing yet
      }
    }

    map(ligthningChargersIds, chargerId => fetchData(chargerId));
  }

  useEffect(() => {
    if (!loading) {
      return;
    }

    getChargersData();
    setLoading(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MainLayout>
      <Header
        getAllChargers={() => {
          setData([]);
          getChargersData();
        }}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Lightning Chargers in Denmark</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Location</th>
                    <th scope="col">City</th>
                    <th scope="col">Power</th>
                    <th scope="col">Status</th>
                    <th scope="col">Chargers</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <>
                      <ChargerPlaceholder />
                      <ChargerPlaceholder />
                      <ChargerPlaceholder />
                    </>
                  )}
                  {!loading && map(chargers, charger => <Item key={charger.id} {...charger} />)}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default Lightning;
