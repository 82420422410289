import Axios from 'axios';
import { filter, map, truncate, uniqueId } from 'lodash';
import moment from 'moment';
import { arrayOf, shape, string } from 'prop-types';
import React, { useEffect, useState, useReducer } from 'react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardHeader,
  Container,
  Media,
  Modal,
  Row,
  Table,
} from 'reactstrap';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import ParkingIcon from 'assets/img/icons/parking.png'
import Connector from 'components/Connector';
import ConnectorData from 'components/Connector/TableData';
import Header from 'components/Headers/Header.js';
import ChargerPlaceholder from 'components/Placeholders/Charger';
import MainLayout from 'layouts/Main';

const Icon = styled.a`
  background-color: #fff;
`;

const ModalBody = styled(Container)`
  width: 80%;

  .connectors {
    margin-bottom: 20px;
  }
`;

const Item = ({ id, address, city, supportPhoneNumber, evses, operatorName, name }) => {
  const [isOpen, setOpen] = useState(false);
  const availableCharger = filter(evses, ev => ev.available && ev.currency !== 'EUR');
  const filteredChargers = filter(evses, ['currency', 'DKK']);

  const getStatus = () => {
    const isAvailable = availableCharger.length > 0;

    return (
      <Badge color="" className="badge-dot mr-4">
        <i className={`bg-${isAvailable ? 'success' : 'danger'}`} />
        {isAvailable ? 'Available' : 'Busy'}
      </Badge>
    );
  };

  return (
    <>
      <tr style={{cursor: 'pointer'}} onClick={() => setOpen(true)}>
        <th scope="row">
          <Media className="align-items-center">
            <Icon
              className="avatar rounded-circle mr-3"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <img
                alt="..."
                src={ParkingIcon}
              />
            </Icon>
            <Media>
              <span className="mb-0 text-sm">
                {truncate(name)}
              </span>
            </Media>
          </Media>
        </th>
        <td>{operatorName}</td>
        <td>
          {getStatus()}
        </td>
        <td className='text-center'>
          <span className="mb-0 text-sm">
            {`${availableCharger.length} / ${filteredChargers.length}`}
          </span>
        </td>
      </tr>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => setOpen(!isOpen)}
        size="lg"
        scrollable
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            {name}
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <ModalBody>
            <Alert className="alert-default">Connectors</Alert>
            <div className='connectors'>
              <Row>
                {map(evses, ({ connectors, available, currency }) => <Connector key={uniqueId()} connectors={connectors} available={available} currency={currency} />)}
              </Row>
            </div>
            <Alert className="alert-default">Other details</Alert>
            <ConnectorData
              id={id}
              address={address}
              city={city}
              supportPhoneNumber={supportPhoneNumber}
              operatorName={operatorName}
              name={name}
            />
          </ModalBody>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <a href={`http://maps.google.com/maps?q=${address}, ${city}`} >
            <Button color="primary" type="button">
              Navigate to charger
            </Button>
          </a>
        </div>
      </Modal>
    </>
  );
};

Item.propTypes = {
  evses: arrayOf(shape()).isRequired,
  name: string.isRequired,
  operatorName: string.isRequired,
};

const chargersIds = [
  '13585', // Edvard Thomsens 1
  // '131697', // Edvard Thomsens 2
  '13617', // Kay Fischer
  // '59447', // Kay Fischer 2
  '131706', // Bjergen 1
  '13605', // Bjergen 2
  '13613', // Ejer Billes
  // '131703', // Robert Jacobsens 1
  '13599', // Robert Jacobsens 2
  // '64124', // Richard Mortensens Vej 1
  // '13607', // Richard Mortensens Vej 2
];

const initialState = {
  chargers: [],
  loading: true,
};

const chargersReducer = (state, action) => {
  switch (action.type) {
    case 'addCharger':
      return {
        ...state,
       chargers: [
         ...state.chargers,
         action.value,
       ],
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.value,
        time: action.time,
      };
    case 'reset':
      return {
        ...state,
        chargers: [],
        loading: true,
      };
    default:
      return state;
  }
};

const Dashboard = () => {
  ReactGA.pageview('/dashboard');
  const reducer = useReducer(chargersReducer, initialState);
  const [{ chargers, loading }, dispatch] = reducer;

  const getChargersData = async () => {
    const fetchData = async (chargerId) => {
      try {
        const {
          data: { id, evses, name, operatorName, city, address, supportPhoneNumber },
        } = await Axios.get(`https://api.virta-ev.com/v4/stations/${chargerId}`);

        dispatch({
          type: 'addCharger',
          value: {
            id,
            evses,
            name,
            operatorName,
            city,
            address,
            supportPhoneNumber,
          }
        });
      } catch (err) {
        console.log('here', err);
        // nothing yet
      }
    }

    await Promise.all(map(chargersIds, chargerId => fetchData(chargerId)));
    dispatch({ type: 'setLoading', value: false, time: moment() });
  }

  useEffect(() => {
    if (!loading) {
      return;
    }

    getChargersData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const spirii = async () => {
      const spirii = await Axios.get('https://app.spirii.dk/api/allPublicChargers');
        console.log(spirii);
    }

    spirii();
  }, []);

  return (
    <MainLayout>
      <Header
        getAllChargers={() => {
          dispatch({ type: 'reset' });
          getChargersData();
        }}
        reducer={reducer}
      />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Chargers in Orestad (By og Havn)</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Location</th>
                    <th scope="col">Provider</th>
                    <th scope="col">Status</th>
                    <th scope="col">Chargers</th>
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <>
                      <ChargerPlaceholder />
                      <ChargerPlaceholder />
                      <ChargerPlaceholder />
                    </>
                  )}
                  {!loading && map(chargers, charger => <Item key={charger.id} {...charger} />)}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </MainLayout>
  );
};

export default Dashboard;
