import { head, isEmpty } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Media,
  Row,
} from 'reactstrap';
import ChargerIcon from 'assets/img/icons/power-cable.png';

const Icon = styled.div`
  background-color: transparent;
`;

const StyledCol = styled(Col)`
  margin-top: 10px;
`;

const Connector = ({ connectors, available, currency }) => {
  const connector = head(connectors);

  if (isEmpty(connector)) {
    return null;
  }

  const { currentType, maxKwh, type } = connector;

  return (
    <StyledCol md='6'>
      <div style={{ width: "18rem" }}>
        <Card className="card-stats mb-4 mb-lg-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle className="text-uppercase text-muted mb-0">
                  {`${type} (${currentType})`}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {`${maxKwh} KW`}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon icon-shape text-center text-white rounded-circle shadow">
                  <Media className="align-items-center">
                    <Icon className="avatar rounded-circle mr-3 text-center">
                      <img alt={`${type} (${currentType})`} src={ChargerIcon} />
                    </Icon>
                  </Media>
                </div>
              </Col>
            </Row>
            <p className="mt-3 mb-0 text-muted text-sm">
              <span className={`text-${available ? 'success' : 'danger'} mr-2`}>
                <i className={`fa fa-arrow-${available ? 'up' : 'down'}`} />
                {' '}
                {available ? 'Available' : 'Busy'}
                {' '}
              </span>
            </p>
            <h4>{currency !== 'DKK' && '(This charger may be virtual)'}</h4>
          </CardBody>
        </Card>
      </div>
    </StyledCol>
  )
};

export default Connector;
